@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

* {
  scroll-behavior: smooth;
}

/* For webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #ececee;
}

::-webkit-scrollbar-thumb {
  background: #d6d8dc;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d6d8dc;
}

/* For Firefox */
html {
  scrollbar-width: thin;
  scrollbar-color: #888 #ececee;
}

/* For Edge, IE, and other browsers */
body {
  scrollbar-color: #888 #ececee;
  scrollbar-width: thin;
}

.editable-div-placeholder:empty:before {
  content: attr(data-placeholder);
  color: #aaa;
}

.message-bubble::before {
  content: '';
  position: absolute;
  bottom: -10px;
  border-width: 10px;
  border-style: solid;
}

.message-bubble.left::before {
  left: 10px;
  border-color: white transparent transparent transparent;
}

.message-bubble.right::before {
  right: 10px;
  border-color: white transparent transparent transparent;
}

.ui-controlled-emoji-mart em-emoji-picker {
  width: 100%;
  min-width: 300px;
  resize: horizontal;
  overflow: auto;
  margin: auto;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

p:empty::before {
  content: '';
  display: inline-block;
}

@keyframes spin-slow {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 4s linear infinite;
}

.reactEasyCrop_CropArea,
.reactEasyCrop_CropAreaGrid::before,
.reactEasyCrop_CropAreaGrid::after {
  border-color: #FF600D !important;
}


@media (min-width: 1920px) {
  .banner-v2-responsive {
    max-height: 500px;
  }
}

@media (max-width: 425px) {
  .banner-v2-responsive {
    min-height: auto;
  }
}

@media (max-width: 400px) {
  .banner-v2-responsive {
    object-fit: fill;
  }
}

.content-editable-container a {
  color: #FF600D;
  text-decoration: underline;
}